'use client';

import { useTranslations } from 'next-intl';
import { Link, usePathname } from '@/i18n/routing';
import { Logo } from '@/app/components/Logo';
import appConst from '~/const/appConst';

export function HeaderLogo() {
  const t = useTranslations();
  const pathname = usePathname();

  const isHomePage = pathname === appConst.staticRoutes.index;

  return (
    <div className="header__nav-logo">
      {isHomePage
        ? (
          <h1 className="header__logo">
            <span className="logo">
              <Logo />
              <span className="header__logo-baseline">{t('SEO.NAME')}</span>
            </span>
          </h1>
          )
        : (
          <div className="header__logo">
            <Link
              href={appConst.staticRoutes.index}
              className="logo"
              title={`${t('GLOBAL.GO_BACK')} ${t('SEO.NAME')}`}
              prefetch={false}
            >
              <Logo />
            </Link>
          </div>
          )}
    </div>
  );
}
