'use client';

import { type KeyboardEvent, useContext, useEffect, useState } from 'react';
import { VpButton, VpIcon } from '@vtmn-play/react/headless';
import { useTranslations } from 'next-intl';
import classNames from 'classnames';
import { useAuthenticatedContext } from '../../Provider/AuthenticatedProvider';
import { ScrollContext } from '../../Provider';
import { handleArrowKeyDown, useFlyoutMenu } from '~/hooks/useFlyoutMenu';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';
import { Link, usePathname } from '@/i18n/routing';
import appConst from '~/const/appConst';
import { Avatar } from '~/components/commons/Avatar/Avatar';
import { getLogoutUri } from '@/app/actions/server';
import { useBreakpoint } from '~/hooks/match-media/useBreakpoint';
import { CustomSwipeableDrawer } from '~/components/commons/CustomSwipeableDrawer/CustomSwipeableDrawer';
import { GenericDialog } from '~/components/commons/genericDialog/GenericDialog';
import { dataLinks } from '~/components/Header/AvatarMenu/data';

export function UserMenu() {
  const t = useTranslations();
  const { user, isAuthenticated, redirectToLogin } = useAuthenticatedContext();
  const { isSBreakpoint } = useBreakpoint();
  const { isScrollingUp } = useContext(ScrollContext);
  const pathname = usePathname();

  const {
    flyoutWrapperRef,
    toggleFlyout,
    focusables,
    addToRefs,
    isOpened,
    setIsOpened,
  } = useFlyoutMenu();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleKeyDown = (
    e: KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    handleArrowKeyDown({ focusables, isOpened, setIsOpened })(e);
  };

  const handleLogout = async () => {
    try {
      window.location.href = await getLogoutUri({});
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    isOpened && setIsOpened(isScrollingUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollingUp]);

  return (
    <>
      {isAuthenticatedAsMainUser(user, isAuthenticated) && (
        <Link
          className="header__messages vp-icon-button vp-icon-button--tertiary"
          href={appConst.staticRoutes.messages}
          title={t('NAVIGATION.MESSAGES')}
          prefetch={false}
        >
          <VpIcon name="message" />
        </Link>
      )}
      <div
        className="header__user-identifier"
        data-cy="avatar"
        ref={flyoutWrapperRef}
      >
        <button
          type="button"
          ref={addToRefs}
          onKeyDown={handleKeyDown}
          className={classNames('header__avatar', {
            'header__avatar--opened': isOpened,
          })}
          onClick={
            isAuthenticatedAsMainUser(user, isAuthenticated)
              ? toggleFlyout
              : redirectToLogin
          }
          {...(isAuthenticatedAsMainUser(user, isAuthenticated) && {
            title: t('GLOBAL.MY_PROFILE'),
            'aria-expanded': isOpened,
            'aria-controls': 'profile-menu',
            'aria-labelledby': 'navigation-my_profile',
          })}
        >
          <Avatar
            user={user}
            authenticated={isAuthenticated}
            avatarSize={40}
            ariaHidden
            fromHeader
          />
        </button>
        {isOpened && (
          <div
            id="profile-menu"
            className="header__flyout"
            data-testid="flyout"
          >
            <ul className="header__flyout__inner">
              {dataLinks.map(link => (
                <li key={link.id}>
                  <Link
                    id={`navigation-${link.id}`}
                    className={classNames(
                      'header__button button-wrapper-link',
                      {
                        active: pathname === link.route,
                      },
                    )}
                    data-cy={link.cy}
                    href={link.route}
                    onClick={toggleFlyout}
                    onKeyDown={handleKeyDown}
                    prefetch={false}
                    ref={addToRefs}
                    rel="noindex, nofollow"
                    tabIndex={-1}
                  >
                    {t(link.label)}
                  </Link>
                </li>
              ))}
              <li>
                <button
                  type="button"
                  ref={addToRefs}
                  tabIndex={-1}
                  onKeyDown={handleKeyDown}
                  className="header__button"
                  onClick={() => setIsDialogOpen(true)}
                >
                  <VpIcon name="power" size={16} />
                  {' '}
                  {t('GLOBAL.LOGOUT')}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
      {isSBreakpoint
        ? (
          <CustomSwipeableDrawer
            dataTestId="logout"
            open={isDialogOpen}
            title={t('GLOBAL.LOGOUT_2')}
            onOpen={() => setIsDialogOpen(true)}
            onClose={() => setIsDialogOpen(false)}
          >
            <p>{t('GLOBAL.LOGOUT_CONFIRM')}</p>
            <div className="logout__actions">
              <VpButton
                data-testid="secondary-button"
                variant="secondary"
                size="medium"
                shape="squared"
                onClick={() => setIsDialogOpen(false)}
              >
                {t('PROFILE.GLOBAL.CANCEL')}
              </VpButton>
              <VpButton
                data-cy="confirm-choice"
                data-testid="confirm-choice"
                size="medium"
                shape="squared"
                onClick={handleLogout}
              >
                {t('GLOBAL.LOGOUT')}
              </VpButton>
            </div>
          </CustomSwipeableDrawer>
          )
        : (
          <GenericDialog
            isOpen={isDialogOpen}
            isClosable={false}
            data-testid="GenericDialog"
            primaryButtonTitle={t('GLOBAL.LOGOUT')}
            primaryButtonClick={handleLogout}
            secondaryButtonTitle={t('PROFILE.GLOBAL.CANCEL')}
            secondaryButtonClick={() => setIsDialogOpen(false)}
            content={<p>{t('GLOBAL.LOGOUT_CONFIRM')}</p>}
            title={t('GLOBAL.LOGOUT_2')}
          />
          )}
    </>
  );
}
