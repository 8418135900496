import { useState } from 'react';
import { useLocale } from 'next-intl';
import type { Collection } from '../actions/collection';
import { fetchCollection } from '../actions/server';
import { ApiRoute } from '../utils/request/api-routes';
import { useApiErrorContext } from '../components/Provider';
import type { SportType } from '~/model/SportType';
import { RequestStatus, useRequestState } from '~/hooks/request/useRequestState';
import { AcceptLanguage } from '~/const/locale';

export function useSports() {
  const locale = useLocale();
  const [sports, setSports] = useState<Collection<SportType>>(null);

  const { setError } = useApiErrorContext();
  const { startRequest, handleSuccess, handleError, status } = useRequestState();

  const isFetching = status === RequestStatus.PENDING;

  const handleFetchSports = async () => {
    startRequest();

    const fetchedSports = await fetchCollection<SportType>({
      pathname: ApiRoute.SPORTS,
      revalidate: 3600,
      headers: {
        acceptLanguage: AcceptLanguage[locale],
      },
      unpaginate: true,
    });

    if (fetchedSports['hydra:description']) {
      handleError();
      return setError(fetchedSports);
    }

    setSports(fetchedSports);
    handleSuccess();
  };

  return { sports, handleFetchSports, isFetching };
}
