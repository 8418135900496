'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export function DatadogRUM() {
  useEffect(() => {
    if (process.env.DATADOG_RUM_APP_ID && process.env.DATADOG_RUM_CLI_ID) {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push((Didomi) => {
        if (Didomi.isConsentRequired()) {
          Didomi.getObservableOnUserConsentStatusForVendor(
            process.env.DATADOG_RUM_DID_ID,
          ).subscribe((consentStatusForVendor) => {
            if (consentStatusForVendor === true) {
              datadogRum.init({
                applicationId: process.env.DATADOG_RUM_APP_ID,
                clientToken: process.env.DATADOG_RUM_CLI_ID,
                site: 'datadoghq.eu',
                service: 'cocreation-front',
                sessionSampleRate: 100,
                sessionReplaySampleRate: Number(process.env.DATADOG_RUM_SES_RP),
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
                version: process.env.RELEASE_VERSION,
              });
              datadogRum.startSessionReplayRecording();
            }
          });
        }
      });
    }
  }, []);
  return null;
};
