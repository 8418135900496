'use client';

import { useTranslations } from 'next-intl';
import { VpButton } from '@vtmn-play/react/headless';
import { useAuthenticatedContext } from '../../Provider/AuthenticatedProvider';
import { UserMenu } from '../UserMenu/UserMenu';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';

export const HeaderAction = () => {
  const t = useTranslations('GLOBAL');
  const { user, isAuthenticating, isAuthenticated, redirectToLogin } = useAuthenticatedContext();

  return (
    <div className="header__nav-user">
      {isAuthenticatedAsMainUser(user, isAuthenticated)
        ? (
          <UserMenu />
          )
        : (
          <VpButton
            disabled={isAuthenticating}
            size="small"
            onClick={redirectToLogin}
          >
            {t('LOGIN_2')}
          </VpButton>
          )}
    </div>
  );
};
