'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export const CookiesManagementBtn = () => {
  const t = useTranslations('NAVIGATION');

  const [isDidomiReady, setIsDidomiReady] = useState(false);

  const openDidomiPreferences = () => {
    window.Didomi.preferences.show();
  };

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(() => setIsDidomiReady(true));
  }, []);

  return isDidomiReady
    ? (
      <li>
        <button
          type="button"
          className="vp-link vp-link--small vp-link--tertiary vp-link--on-brand"
          onClick={openDidomiPreferences}
        >
          <span className="vp-link__label" data-part="label">
            {t('COOKIES_MANAGEMENT')}
          </span>
        </button>
      </li>
      )
    : null;
};
