'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { useSports } from '@/app/hooks/useSports';

export const SportsContext = createContext<ReturnType<typeof useSports> | undefined>(undefined);

export const useSportsContext = () => {
  const context = useContext(SportsContext);
  if (!context) {
    throw new Error(
      'useSportsContext must be called within SportsProvider',
    );
  }
  return context;
};

export function SportsProvider({ children }: PropsWithChildren) {
  const value = useSports();

  return (
    <SportsContext.Provider value={value}>
      {children}
    </SportsContext.Provider>
  );
}
