'use client';

import { useLocale } from 'next-intl';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useAuthenticatedContext } from '@/app/components/Provider';
import { AcceptLanguage } from '~/const/locale';
import { convertLocaleSeparator } from '~/utils/utils';

export function Medallia() {
  const locale = useLocale();
  const { user } = useAuthenticatedContext();

  const [medalliaConsentStatus, setMedalliaConsentStatus] = useState(false);

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push((Didomi) => {
      if (Didomi.isConsentRequired()) {
        Didomi.getObservableOnUserConsentStatusForVendor(
          process.env.MEDALLIA_VENDOR_ID,
        ).subscribe((consentStatusForVendor) => {
          setMedalliaConsentStatus(consentStatusForVendor);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (medalliaConsentStatus) {
      window.medalliaCustomParams = {
        languageCode: convertLocaleSeparator({ locale: user?.favorite_locale || AcceptLanguage[locale] }),
        ...user?.id && {
          jobName: user.job,
          uid: `${user.id}`,
        },
        env: process.env.ENVIRONMENT,
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, locale, medalliaConsentStatus]);

  return (
    medalliaConsentStatus
      ? (
        <Script
          id="medallia"
          type="text/javascript"
          strategy="lazyOnload"
          src={`https://resources.digital-cloud-west.medallia.com/wdcwest/${process.env.MEDALLIA_PROPERTY_ID}/onsite/embed.js`}
        />
        )
      : null
  );
}
