'use client';

import { useEffect, useReducer, useState } from 'react';
import classNames from 'classnames';
import type { MessageKeys } from 'next-intl';
import { useTranslations } from 'next-intl';
import type { VpIconName } from '@vtmn-play/react/headless';
import { VpIcon } from '@vtmn-play/react/headless';
import { PopperComponent } from '~/components/commons/Popper/PopperComponent';
import { ThemeType } from '~/model/GlobalTypes';
import {
  getCookie,
  getLocalStorage,
  getUserPreferedColorScheme,
  isPreferedColorSchemeSupported,
  setCookie,
  setLocalStorage,
} from '~/utils/utils';
import layout from '~/const/layout';
import type { Messages } from '@/global';

const getIconFromTheme = (value: ThemeType): VpIconName => {
  switch (value) {
    case ThemeType.light:
      return 'sun';
    case ThemeType.dark:
      return 'moon';
    default:
      return 'mac';
  }
};

const getCurrentIcon = ({ isSystemTheme, userPreferedColorScheme, theme }) =>
  isSystemTheme
    ? getIconFromTheme(userPreferedColorScheme || ThemeType.light)
    : getIconFromTheme(theme);

export const ThemeSwitcher = () => {
  const t = useTranslations('GLOBAL');

  const theme = getCookie('theme') || getLocalStorage('theme') || ThemeType.system;

  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [isPopperOpened, setIsPopperOpened] = useState(false);
  const [mounted, setMounted] = useState(false);

  const isSystemTheme = theme === ThemeType.system;
  const isThemeDefined = !isSystemTheme;
  const userPreferedColorScheme = getUserPreferedColorScheme();

  const handleSwitch = (value: ThemeType) => {
    setLocalStorage('theme', value);
    setCookie(`theme=${value}`);
    setIsPopperOpened(false);
  };

  const themeClassSuffix = isThemeDefined ? theme : userPreferedColorScheme;
  const themeClassBase = 'vtmn-new-visual-identity vp--wonder-theme';
  const themeClass = themeClassSuffix
    ? `${themeClassBase} vp--${themeClassSuffix}-mode vtmn-new-visual-identity--${themeClassSuffix}`
    : themeClassBase;

  const currentIcon = getCurrentIcon({
    isSystemTheme,
    userPreferedColorScheme,
    theme,
  });

  const currentThemeLabel = t(
    `THEME.${theme.toUpperCase()}` as MessageKeys<
      Messages,
      'THEME.DARK' | 'THEME.LIGHT' | 'THEME.SYSTEM'
    >,
  );

  const openerLabel = `${t('THEME.CHANGE')} : ${currentThemeLabel}`;

  useEffect(() => {
    // If user changes his prefered color scheme
    // and system mode is selected,
    // force a rerender to compute user preference's new value
    const mql = window.matchMedia(layout.matchMedia.dark);

    const handleChangeColorScheme = () => {
      const updatedPreference = getUserPreferedColorScheme();
      if (isSystemTheme && updatedPreference) {
        forceUpdate();
      }
    };

    mql.addEventListener('change', handleChangeColorScheme);
    // Remove event listener on unmount
    return () => {
      mql.removeEventListener('change', handleChangeColorScheme);
    };
  }, []);

  useEffect(() => {
    document.querySelector('body').className = themeClass;
  }, [themeClass]);

  // Mount the component only client-side (via useEffect)
  // to avoid mismatched content between server and client
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    mounted && (
      <PopperComponent
        ariaLabel={openerLabel}
        arrow
        isOpen={isPopperOpened}
        placement="top"
        setIsOpen={setIsPopperOpened}
        text={(
          <span className="vp-link__label" data-part="label">
            <span data-part="end-slot">
              <VpIcon name={currentIcon} size={16} />
            </span>
            {openerLabel}
            <span data-part="end-slot">
              <VpIcon
                name={`chevron-${isPopperOpened ? 'up' : 'down'}`}
                size={16}
              />
            </span>
          </span>
        )}
      >
        <ul tabIndex={-1}>
          {Object.values(ThemeType).map((mode, index) => {
            const isActive = theme === mode;
            // Hide "System" option if the browser does not support media query prefers-color-scheme
            const shouldBeHidden
              = !isPreferedColorSchemeSupported() && mode === ThemeType.system;
            if (shouldBeHidden)
              return null;
            return (
              <li key={mode}>
                <button
                  type="button"
                  id={`popper-${mode}`}
                  className={classNames('popper__option', {
                    'popper__option--active': isActive,
                  })}
                  {...(index === 0 && { autoFocus: true })}
                  onClick={() => handleSwitch(mode)}
                >
                  <VpIcon
                    aria-hidden
                    name={getIconFromTheme(mode)}
                    size={20}
                  />
                  <span>
                    {t(`THEME.${mode.toUpperCase()}` as MessageKeys<
                        Messages,
                        | 'THEME.DARK'
                        | 'THEME.LIGHT'
                        | 'THEME.SYSTEM'
                      >,
                    )}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </PopperComponent>
    )
  );
};
